import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { LoanProposalModel } from '../models/InitDetails.model';

import { environment } from 'src/environments/environment';

@Injectable()
export class CalculadoraService {
    constructor(
        private http: HttpClient
    ) { }

    amountOutOfBounds$ = new BehaviorSubject<boolean>(false);

    initDetails(accesstoken: string): Observable<LoanProposalModel> {
        const params = {
        };
        const headers = { // TODO: Remove & replace by token interceptor at main project
            headers: new HttpHeaders({authorization: `Bearer ${accesstoken}`})
        };

        return this.http
            .post<LoanProposalModel>(
                environment.host + environment.api + '/InitDetails',
                params,
                headers
            );
    }

    getCalculate(accesstoken, loanObj: LoanProposalModel): Observable<LoanProposalModel> {
        const params = {
            loanObj
        };
        const headers = { // TODO: Remove & replace by token interceptor at main project
            headers: new HttpHeaders({authorization: `Bearer ${accesstoken}`})
        };
        return this.http
            .post<LoanProposalModel>(
                environment.host + environment.api + '/GetCalculate',
                params,
                headers
            );
    }

    getLoan(
        accesstoken: string,
        loanObj: LoanProposalModel,
        urlToCall: string
    ): Observable<any> {
        const params = {
            loanObj
        };
        const headers = { // TODO: Remove & replace by token interceptor at main project
            headers: new HttpHeaders({authorization: `Bearer ${accesstoken}`})
        };
        return this.http
            .post<any>(
                environment.host + environment.api + urlToCall,
                params,
                headers
            );
    }
}
