import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppCalculadoraComponent } from './app-calculadora.component';
import { AppSliderComponent } from './components/slider/app-slider.component';
import { AppCalculateComponent } from './components/slider/components/calculate/app-calculate.component';

import { SliderModule } from 'primeng/slider';

import { CalculadoraService } from './services/calculadora.service';
import { SetDecimals } from './pipes/setDecimals.pipe';

registerLocaleData(localeEs, 'es-ES');

@NgModule({
  declarations: [
    AppCalculadoraComponent,
    AppSliderComponent,
    AppCalculateComponent,
    SetDecimals
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    SliderModule
  ],
  exports: [
    AppCalculadoraComponent
  ],
  providers: [
    CalculadoraService
  ]
})
export class AppCalculadoraModule { }
