import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CalculateModel } from '../../models/Calculate.model';

import { InitDetailsModel, LoanProposalModel } from '../../models/InitDetails.model';
import { CalculadoraService } from '../../services/calculadora.service';

@Component({
  selector: 'app-slider',
  templateUrl: './app-slider.component.html',
  styleUrls: ['./app-slider.component.scss']
})
export class AppSliderComponent implements OnInit {

  calculateValues = {
    amount: 0,
    minAmount: 0,
    maxAmount: 0,
    stepAmount: 0
  };

  calculateValues2 = {
    amount: 0,
    minAmount: 0,
    maxAmount: 0,
    stepAmount: 0
  };

  initData;

  outOfAmountBounds = false;

  @Input() initData$: Observable<LoanProposalModel>;
  @Input() accesstoken: string;

  @Output() outputData = new EventEmitter<any>();

  constructor(
    private calculadoraService: CalculadoraService
  ) {}

  ngOnInit() {
    this.initData$
    .pipe(
      filter(data => !!data)
    )
    .subscribe( (data: LoanProposalModel) => {
      this.initData = {...data};

      this.calculateValues = {
        amount: data.Amount,
        minAmount: data.Amount_Min,
        maxAmount: data.Amount_Max,
        stepAmount: data.Length_Amount
      };

      this.calculateValues2 = {
        amount: data.Deadlines,
        minAmount: data.Deadline_Min,
        maxAmount: data.Deadline_Max,
        stepAmount: data.Length_Deadlines
      };

    });
  }

  calculate(ammountValue: number, isQuantity: boolean) {

    if (isQuantity) {
      this.initData.Amount = ammountValue;
    } else {
      this.initData.Deadlines = ammountValue;
    }

    this.calculadoraService.getCalculate(
      this.accesstoken,
      this.initData
    ).pipe(
      filter( data => !!data)
    ).subscribe( (data: LoanProposalModel)  => {
      this.initData = {...this.initData, ...data};
      // this.initData = {...data, ...{Amount: this.initData.Amount}, ...{Deadlines: this.initData.Deadlines}};

      /* Start code to re-set values in months slider */

      this.calculateValues2 = {
        amount: data.Deadlines,
        minAmount: data.Deadline_Min,
        maxAmount: data.Deadline_Max,
        stepAmount: data.Length_Deadlines
      };
      /* End code to re-set values in sliders */

      this.outputData.emit({...this.initData});
    });
  }

  broadCastAmountValue(event) {
    this.calculate(event, true);
  }

  broadCastMonthsValue(event) {
    this.calculate(event, false);
  }
}
