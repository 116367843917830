import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { CalculadoraService } from 'src/app/calculadora/services/calculadora.service';

@Component({
  selector: 'app-calculate',
  templateUrl: './app-calculate.component.html',
  styleUrls: ['./app-calculate.component.scss']
})
export class AppCalculateComponent implements OnChanges{

  sendOutputDataInterval;
  outOfAmountBounds = false;
  ticks = [];

  editMode = false;

  @Input() sliderValues;
  @Input() isMoney;
  @Input() hasTicks;
  @Output() outputData = new EventEmitter<any>();

  @ViewChild('inputEdit', { static: false }) inputEdit: ElementRef<HTMLInputElement>;

  constructor(
    private calculadoraService: CalculadoraService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnChanges(change) {
    if (change.sliderValues.currentValue){
      this.changeDetector.detectChanges();
      if (this.hasTicks){
        this.setNumberOfTicks();
      }
    }
  }

  setNumberOfTicks() {
    this.ticks = [];
    const steps = +((this.sliderValues.maxAmount - this.sliderValues.minAmount) / this.sliderValues.stepAmount).toFixed(0) + 1;
    for(let i=0;i<steps;i++){
      if (i === 0) {
        this.ticks.push(this.sliderValues.maxAmount);
      }else {
        this.ticks.push(this.sliderValues.maxAmount - (this.sliderValues.stepAmount * (i + 1)));
      }
    }
  }

  checkOutOfBounds(timeout?: number, ammountValue?: number) {
    this.checkIllegalCharsAtEditAmountInput();
    timeout = !!timeout ? timeout : 0;
    ammountValue = !!ammountValue ? this.numberTreatment(ammountValue.toString()) : +this.sliderValues.amount;
    if (!!this.sendOutputDataInterval) {
      clearInterval(this.sendOutputDataInterval);
    }
    if (
      this.editMode ?
        !!this.inputEdit.nativeElement.value &&
        !!ammountValue &&
        ammountValue >= +this.sliderValues.minAmount &&
        ammountValue <= +this.sliderValues.maxAmount
      :
        !!ammountValue &&
        ammountValue >= +this.sliderValues.minAmount &&
        ammountValue <= +this.sliderValues.maxAmount
    ) {

      this.outOfAmountBounds = false;
      this.calculadoraService.amountOutOfBounds$.next(false);
      this.sendOutputDataInterval = setInterval( () => {
        this.sendOutputData(ammountValue);
      }, timeout);

    } else {
      this.outOfAmountBounds = true;
      this.calculadoraService.amountOutOfBounds$.next(true);
    }
  }

  checkIllegalCharsAtEditAmountInput() {
    if (!!this.editMode) {
      this.inputEdit.nativeElement.value = this.numberTreatment(this.inputEdit.nativeElement.value).toString();
      if (this.inputEdit.nativeElement.value === '0' || isNaN(+this.inputEdit.nativeElement.value)) {
        this.inputEdit.nativeElement.value = '';
      }
    }
  }

  sendOutputData(ammountValue?) {
    if (ammountValue) {
      this.sliderValues.amount = ammountValue;
    }
    this.outOfAmountBounds = false;
    this.calculadoraService.amountOutOfBounds$.next(false);
    this.outputData.emit(this.sliderValues.amount);
    if (!!this.sendOutputDataInterval) {
      clearInterval(this.sendOutputDataInterval);
    }
  }

  numberTreatment(value: string) {
    return +Number((value.replace(/\./g, '')).replace(/[^.0-9]/g, ''));
  }

  removeEditMode() {
    if (!!this.sendOutputDataInterval) {
      clearInterval(this.sendOutputDataInterval);
    }
    this.inputEdit.nativeElement.value = this.sliderValues.amount;
    this.editMode = false;
    this.checkOutOfBounds();
  }

  setMinValue() {
    this.setValueToModel({value: this.sliderValues.minAmount});
  }

  setMaxValue() {
    this.setValueToModel({value: this.sliderValues.maxAmount});
  }

  setValueToModel(qty) {
    this.sliderValues.amount = qty.value;
    this.checkOutOfBounds(1000);
  }

  setFocusToEditInput() {
    this.editMode = true;
    setTimeout( () => {
      this.inputEdit.nativeElement.focus();
      this.inputEdit.nativeElement.setSelectionRange(0, 0);
    }, 0);
  }
}
